import { device } from "src/styles/breakpoints"
import styled from "styled-components"

export const TextContent = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1.5rem;

  .body-text-box {
    max-width: 312px;
    margin: 0 auto;
  }

  h2 {
    margin-bottom: 1rem;
  }

  @media ${device.tablet} {
    & {
      margin-bottom: 1rem;
    }
    .body-text-box {
      max-width: 504px;
    }
  }

  @media ${device.desktopLG} {
    & {
      max-width: 954px;
    }
    &, h2 {
      margin-bottom: 1.5rem;
    }
    .body-text-box {
      max-width: 591px;
    }
  }

  @media ${device.desktopXXLL} {
    .body-text-box {
      max-width: 695px;
    }
  }
`

export const CtaBox = styled.div`
  margin: 0 auto;
  @media ${device.tablet} {
    max-width: 265px;
  }

  @media ${device.desktopLG} {
    max-width: 295px;
  }

  @media ${device.desktopXL} {
    max-width: 362px;
  }

  @media ${device.desktopXXLL} {
    max-width: 455px;
  }
`

export const CarouselBox = styled.div`

  & .react-multi-carousel-track {
    padding: 0;
    padding-bottom: 1rem;
  }
  .carousel-item,
  & .react-multi-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-item {
    min-height: 148px;
    min-width: 148px;
  }

  & .react-multi-carousel-item {
    margin-right: 0.5rem;
  }

  .logos {
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(40px, 1fr));
    row-gap: 4px;
  }
`

export const ProductBackgroundBox = styled.div`
  min-height: 327px;
  position: relative;
  margin-bottom: 1rem;

  @media ${device.desktopLG} {
    min-height: 451px;
  }

  @media ${device.desktopXL} {
    min-height: 509px;
    margin-bottom: 2rem;
  }

  @media ${device.desktopXXLL} {
    min-height: 644px;
  }
`

export const ProductBackground = styled.div`
  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-dobra5-web/image.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 110%;
  height: 100%;
  position: absolute;
  left: -2.5rem;

  @media ${device.desktopXL} {
    width: 130%;
    left: -10.5rem;
  }

  @media ${device.desktopXXLL} {
    width: 135%;
    left: -15.5rem;
  }
`
