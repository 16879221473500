import { device } from "src/styles/breakpoints"
import { graphite } from "src/styles/newColors"
import styled from "styled-components"

export const ColoredTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 8px;
  @media ${device.tablet} {
    justify-content: flex-start;
  }
`

export const ParceleSuasComprasTextWrapper = styled.div`
  & .body-text {
    color: ${graphite};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  @media ${device.tablet} {
    & .body-text {
      text-align: left;
    }
  }

  @media ${device.desktopLG} {
    & .body-text {
      font-size: 16px;
    line-height: 20px;
    }
  }

  @media ${device.desktopXL} {
    & .body-text {
      font-size: 18px;
    line-height: 22px;
    }
  }

  @media ${device.desktopXXL} {
    & .body-text {
      font-size: 20px;
    line-height: 24px;
    }
  }
`
