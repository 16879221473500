import { device } from "src/styles/breakpoints"
import { graphite, orange } from "src/styles/newColors"
import styled from "styled-components"

export const OQueECrediarioTextWrapper = styled.div`
  .text-content__header,
  .text-content__body {
    margin-bottom: 1.5rem;
  }

  .text-content__body {
    & .text-content__paragraph {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
      color: ${graphite};
    }
  }

  @media ${device.tablet} {
    .text-content__body {
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media ${device.desktopLG} {
    .text-content__body {
      max-width: 548px;
      & .text-content__paragraph {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  @media ${device.desktopXXL} {
    .text-content__body {
      max-width: 597px;
      & .text-content__paragraph {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  @media ${device.desktopXXLL} {
    .text-content__body {
      max-width: 697px;
      & .text-content__paragraph {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`

export const BrownPanel = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${orange.clay};
  border-radius: 12px;
  h3,
  p {
    font-size: 12px;
    line-height: 14px;
    color: #fff;
  }

  h3 {
    margin-bottom: 4px;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
    font-weight: 400;
  }

  @media ${device.desktopLG} {
    h3,
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media ${device.desktopXXL} {
    h3,
    p {
      font-size: 18px;
      line-height: 21px;
    }
  }

  @media ${device.desktopXXLL} {
    h3,
    p {
      font-size: 20px;
      line-height: 24px;
    }
  }
`

export const CreditoLiberado = styled(BrownPanel)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  & .text-content {
    padding: 12px;
    padding-right: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .text-content__paragraph {
      margin-bottom: 0.5rem;
    }

    & .text-content__paragraph--note {
      font-size: 10px;
      font-weight: 400;
    }
  }

  & .img-content {
    padding-top: 0.5rem;
  }

  @media ${device.tablet} {
    & .text-content {
      & .text-content__paragraph {
        margin-bottom: 0.25rem;
      }
    }

    & .img-content {
      padding-top: 0.25rem;
    }
  }

  @media ${device.desktopLG} {
    grid-template-columns: 1.2fr 1fr;
    & .text-content {
      padding: 18px 32px;
      & .text-content__paragraph--note {
        font-size: 12px;
        line-height: 14px;
      }
    }

    & .img-content {
      display: flex;
      align-items: end;
      img {
        width: auto !important;
        max-width: 100%;
      }
    }
  }

  @media ${device.desktopXXL} {
    grid-template-columns: 1.3fr 1fr;
    & .text-content {
      padding: 21px 40px;
      & .text-content__paragraph--note {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  @media ${device.desktopXXLL} {
    & .text-content {
      padding: 34px 50px;
      & .text-content__paragraph--note {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

`

export const ParcelasQueCabemNoSeuBolso = styled(BrownPanel)`
  display: grid;
  padding: 12px;
  grid-template-columns: auto 1fr;

  & .text-content {
    padding-left: 0.25rem;
  }

  @media ${device.tablet} {
    align-items: center;
    & .text-content {
      padding-left: 0.5rem;
      h3 {
        margin: 0;
      }
    }
  }

  @media ${device.desktopLG} {
    padding: 12px 16px;
    & .text-content {
      padding-left: 1.2rem;
    }
  }

  @media ${device.desktopXXLL} {
    padding: 25px 40px;
  }
`

export const NaoPrecisaDeCartao = styled(ParcelasQueCabemNoSeuBolso)`
  align-items: unset;

  @media ${device.desktopXXL} {
    padding: 20px 26px;
  }

  @media ${device.desktopXXLL} {
    padding: 32px 37px;
  }
`

export const OrganizacaoFinanceira = styled(NaoPrecisaDeCartao)``

export const PraticidadePraPagar = styled(BrownPanel)`
  padding: 12px 10px;
  .text-content {
    margin-bottom: 0.3rem;
    & .text-content__paragraph {
      line-height: 12.5px;
    }
  }

  .img-content {
    display: flex;
    align-items: center;
  }

  @media ${device.tablet} {
    padding: 18px 22px;
    .text-content {
      margin-bottom: 0.5rem;
    }
  }
  @media ${device.desktopLG} {
    padding: 30px;
    .text-content {
      margin-bottom: 1.2rem;
    }
  }

  @media ${device.desktopXXL} {
    padding: 33px 38px;
  }

  @media ${device.desktopXXLL} {
    padding: 47px 42px;
    .text-content {
      margin-bottom: 2rem;
    }
  }
`

// define somente o grid e o layout dos paineis do grid
export const OQueECrediarioGrid = styled.div`
  display: grid;
  width: 100%;
  min-height: 336px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;

  &.grid--row-oriented {
    grid-template-rows: auto 100px 132px;
    grid-row-gap: 8px;

    & .grid-row {
      display: grid;
      grid-column-gap: 8px;
      grid-column: 1 / -1;
    }

    & .grid-row.grid-row--first {
      ${CreditoLiberado} {
        grid-row: 1 / 2;
        grid-column: 1 / -1;
      }
    }

    & .grid-row.grid-row--second {
      grid-row: 2 / 3;
      grid-template-columns: minmax(135px,1fr) minmax(168px, 2fr);

      ${ParcelasQueCabemNoSeuBolso} {
        grid-column: 1 / 2;
      }
      ${NaoPrecisaDeCartao} {
        grid-column: 2 / -1;
      }
    }

    & .grid-row.grid-row--third {
      grid-row: 3 / -1;
      grid-template-columns: minmax(149px, 2fr) minmax(155px, 2fr);

      ${OrganizacaoFinanceira} {
        grid-column: 1 / 2;
      }

      ${PraticidadePraPagar} {
        grid-column: 2 / -1;
      }
    }
  }

  @media ${device.tablet} {
    &.grid--column-oriented {
      grid-template-columns: 264px 219px 192px;
      gap: 8px;
      min-height: 150px;

      & .grid-column {
        display: grid;
        grid-row-gap: 8px;
      }

      & .grid-column.grid-column--first {
        grid-template-rows: 88px 50px;
      }

      & .grid-column.grid-column--second {
        grid-template-rows: minmax(58px, 1fr) 81px;
      }

      & .grid-column.grid-column--third {
        grid-template-rows: minmax(auto, 1fr);
      }
    }
  }

  @media ${device.desktopLG} {
    &.grid--column-oriented {
      margin-bottom: 1rem;
      max-width: 935px;
      gap: 12px;
      grid-template-columns: 355px 294px 260px;

      & .grid-column {
        grid-row-gap: 12px;
      }

      & .grid-column.grid-column--first {
        grid-template-rows: 120px 68px;
      }

      & .grid-column.grid-column--second {
        grid-template-rows: 90px 100px;
      }
    }
  }

  @media ${device.desktopXXL} {
    &.grid--column-oriented {
      margin-bottom: 2rem;
      max-width: 1126px;
      grid-template-columns: 429px 355px 313px;
      gap: 16px;

      & .grid-column {
        grid-row-gap: 16px;
      }

      & .grid-column.grid-column--first {
        grid-template-rows: 145px 82px;
      }

      & .grid-column.grid-column--second {
        grid-template-rows: 108px 120px;
      }
    }
  }

  @media ${device.desktopXXLL} {
    &.grid--column-oriented {
      margin-bottom: 2.5rem;
      max-width: 1414px;
      gap: 20px;
      grid-template-columns: 538px 446px 392px;

      & .grid-column {
        grid-row-gap: 20px;
      }

      & .grid-column.grid-column--first {
        grid-template-rows: 181px 100px;
      }

      & .grid-column.grid-column--second {
        grid-template-rows: 136px 149px;
      }
    }
  }
`

export const CtaBox = styled.div`
  width: 100%;
  @media ${device.tablet} {
    width: 23rem;
  }
`
