import React from 'react'
import * as S from './styles'
import { CrediarioDigitalSectionProps } from '../../types'
import SectionComponent from 'src/components/SectionComponent'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import { CrediarioDigitalH2 } from '../../styles'
import { orange } from 'src/styles/newColors'
import { InterP } from 'src/components/Text/BodyText'
import { ButtonWithDeepLink } from '../components/_ButtonWithDeepLink'
import { WIDTH_MD } from 'src/styles/breakpoints'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import Picture from 'src/components/Picture'
import { useQRCodeModal } from 'src/context/QRCodeModalContext/QRCodeModalContext'

type CompreEPagueProps = CrediarioDigitalSectionProps

const TEXT_CONTENT = {
  heading: 'Compre em mais de 600 lojas e pague com tranquilidade, em até 24x',
  body: 'Se você está procurando <strong>lojas que fazem crediário online</strong>, acabou de encontrar a melhor opção do mercado! No Shopping do Inter, você compra nas maiores lojas e marcas, escolhe entre milhares de produtos e <strong>parcela no Crediário Digital.</strong>',
  carouselTop: [
    { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-fone-mobile/image.webp', alt: 'Compre e pague' },
    {
      logos: [
        { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-samsumg-marca/image.webp', alt: 'Samsung' },
        { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-britania/image.webp', alt: 'Britania' },
        { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-bahia-360/image.webp', alt: 'Casas Bahia' },
      ],
    },
    { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/airfryer-crediario-360/image.webp', alt: 'Fritadeira' },
    {
      logos: [
        { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-madeira/image.webp', alt: 'Madeira Madeira' },
        { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-apple/image.webp', alt: 'Apple' },
      ],
    },
  ],
  carouselBottom: [
    { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/shop-led/image.webp', alt: 'TV LED' },
    { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/shop-perfume/image.webp', alt: 'Perfume' },
    {
      logos: [
        { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-acer-360/image.webp', alt: 'Acer' },
        { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-centauro-360/image.webp', alt: 'Centauro' },
        { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-eletrolux/image.webp', alt: 'Eletrolux' },
      ],
    },
    { src: 'https://central-imagens.bancointer.com.br/images-without-small-versions/adidas-crediario-360/image.webp', alt: 'Tênis' },
  ],
  cta: 'Quero comprar parcelado',
}

const CompreEPague = ({ id, width, sendDataLayerEvent, dataLayerSection }: CompreEPagueProps) => {
  const url = 'https://intergo.app/2ae88a71'
  // eslint-disable-next-line @typescript-eslint/typedef
  const { setIsModalOpen } = useQRCodeModal()

  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })

    if (width >= WIDTH_MD) {
      setIsModalOpen(true)
    }
  }
  return (
    <SectionComponent
      id={id}
      minHeight={{ sm: '704px', md: '646px', lg: '885px', xl: '1031px', xxl: '1258px' }}
    >
      <div className='col-12'>
        <S.TextContent>
          <CrediarioDigitalH2 color={orange.extra}>{TEXT_CONTENT.heading}</CrediarioDigitalH2>
          <div className='body-text-box'>
            <InterP dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.body }} />
          </div>
        </S.TextContent>
      </div>
      <div className='col-12'>
        {width < WIDTH_MD && <CompreEPague.MobileView />}
        {width >= WIDTH_MD && <CompreEPague.DefaultView />}
      </div>
      <div className='col-12'>
        <S.CtaBox>
          <ButtonWithDeepLink
            onClick={handleCtaClick}
            renderDeepLinkWhen={width < WIDTH_MD}
            text={TEXT_CONTENT.cta}
            url={url}
          />
        </S.CtaBox>
      </div>
    </SectionComponent>
  )
}

const ProductImg = ({ item }: {item: { src: string; alt: string }}) => (
  <Picture width='100%' src={item.src} alt={item.alt} minHeight={{ sm: 'auto' }} />
)

const Logos = ({ logos }: {logos: { src: string; alt: string }[]}) => (
  <div className='logos'>
    {logos.map((logo, index) => (
      <Picture width='100%' key={logo.alt.trim().toLowerCase() + index} src={logo.src} alt={logo.alt} minHeight={{ sm: 'auto' }} />
    ))}
  </div>
)

CompreEPague.MobileView = () => (
  <S.CarouselBox>
    <DefaultCarousel
      autoPlay
      infinite
      notShowArrows
      notShowDots
      sm={{ items: 2, partialVisibilityGutter: 10 }}
    >
      {TEXT_CONTENT.carouselTop.map((item, index) => (
        <div className='carousel-item' key={'carousel-top-item-' + index}>
          {item.src && (<ProductImg item={item} />)}
          {item.logos && (<Logos logos={item.logos} />)}
        </div>
      ))}
    </DefaultCarousel>
    <DefaultCarousel
      autoPlay
      sm={{ items: 2, partialVisibilityGutter: 10 }}
      infinite
      notShowArrows
      notShowDots
    >
      {TEXT_CONTENT.carouselBottom.map((item, index) => (
        <div className='carousel-item' key={'carousel-bottom-item-' + index}>
          {item.src && (<ProductImg item={item} />)}
          {item.logos && (<Logos logos={item.logos} />)}
        </div>
      ))}
    </DefaultCarousel>
  </S.CarouselBox>
)

CompreEPague.DefaultView = () => {
  return (
    <S.ProductBackgroundBox>
      <S.ProductBackground />
    </S.ProductBackgroundBox>
  )
}

export default withCommonSectionProps(CompreEPague)
