import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { graphite, orange } from "src/styles/newColors"
import styled from "styled-components"

export const TextContent = styled.div`
  text-align: center;
  color: ${graphite};
  h2 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
    text-align: left;

    & .list-item {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      display: grid;
      align-items: center;
      grid-template-columns: auto 1fr;
      font-size: 14px;
      line-height: 16px;
      gap: 1rem;

      & .list-item__index {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        width: 24px;
        height: 24px;
        background-color: ${orange.clay};
        border-radius: 50%;
      }

      & .list-item__text {
        margin: 0;
      }
    }
  }

  @media ${device.tablet} {
    text-align: left;
    margin-bottom: 1.5rem;
    p {
      margin: 0;
    }

    h2, h2 + p {
      margin-bottom: 1rem;
    }
  }

  @media ${device.desktopLG} {
    h2, h2 + p {
      margin-bottom: 1.5rem;
    }
  }

  @media ${device.desktopXL} {
    &, h2, h2 + p, .list {
      margin-bottom: 2rem;
    }

    .list {
      & .list-item {
        font-size: 18px;
        line-height: 21px;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media ${device.desktopXXLL} {
    h2, h2 + p, .list {
      margin-bottom: 3rem;
    }

    .list {
      & .list-item {
        font-size: 20px;
        line-height: 23px;
      }
    }
  }

`

export const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${grayscale[300]};
  border-radius: 32px;
  margin-bottom: 1rem;

  @media ${device.tablet} {
    & {
      max-width: 304px;
    }
  }

  @media ${device.desktopLG} {
    & {
      max-width: 393px;
    }
  }

  @media ${device.desktopXL} {
    & {
      max-width: 409px;
    }
  }
  @media ${device.desktopXXLL} {
    & {
      max-width: 457px;
    }
  }
`

export const CtaBox = styled.div`
  margin: 0 auto;
`
