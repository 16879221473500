import React from 'react'
import FAQ from 'src/components/Faq'

import pageContext from '../../pageContext.json'
import { H2Citrina } from 'src/components/Text/Headings'
import { grayscale } from 'src/styles/colors'
import { InterP } from 'src/components/Text/BodyText'
import SectionComponent from 'src/components/SectionComponent'

const TEXT_CONTENT = {
  heading: 'Como funciona o <span class="text-orange--extra">Crediário?</span>',
  subheading: 'Perguntas frequentes',
  lead: '<strong>O crediário, ou carnê digital, é uma forma de financiamento</strong> oferecida como opção de pagamento pra quem não tem limite de crédito disponível pra comprar. Com ele, é possível fazer uma compra e parcelar em várias prestações. De forma geral, funciona assim:',
  listItems: [
    '<strong>Análise de crédito:</strong> antes de conceder o crédito, é realizada uma análise pra verificar a capacidade de pagamento.',
    '<strong>Crédito liberado:</strong> após a avaliação, um valor de crédito é liberado pra você comprar.',
    '<strong>Compra:</strong> você escolhe o produto que deseja e, como forma de pagamento, escolhe a opção Crediário Digital.',
    '<strong>Entrada e Parcelamento:</strong> ao finalizar a compra, o valor da entrada é descontado direto na conta e as demais parcelas começam a ser debitadas a partir do mês seguinte',
  ],
}

const FAQHomeEquity = ({ id }: {id: string}) => {
  return (
    <SectionComponent SectionStyles='mt-4 mt-md-0' id={id}>
      <div className='col-12'>
        <H2Citrina
          color={grayscale[400]}
          fontSize={{ sm: '24px', md: '28px', lg: '40px', xl: '40px' }}
          lineHeight={{ sm: '28px', md: '32px', lg: '48px', xl: '58px' }}
          className='mb-4 fw-700 text-center text-md-left'
          dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.heading }}
        />
        <InterP dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.lead }} className='mb-3' />
        <ol>
          {TEXT_CONTENT.listItems.map((item, index) => (
            <li key={item.trim().toLocaleLowerCase() + index} className='mb-2 ml-3'>
              <InterP dangerouslySetInnerHTML={{ __html: item }} />
            </li>
              ))}
        </ol>
      </div>
      <div className='col-12 my-5'>
        <H2Citrina
          color={grayscale[400]}
          fontSize={{ sm: '24px', md: '28px', lg: '40px', xl: '40px' }}
          lineHeight={{ sm: '28px', md: '32px', lg: '48px', xl: '58px' }}
          className='mb-4 fw-700 text-center text-md-left'
          dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.subheading }}
        />
        <FAQ
          answerData={pageContext.structuredData.faq}
          columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} className='px-0'
        />
      </div>
    </SectionComponent>
  )
}

export default FAQHomeEquity
