import React from "react"
import { device } from "src/styles/breakpoints"
import { graphite } from "src/styles/newColors"
import styled from "styled-components"
import moneySrc from './assets/money.svg'
import phoneSrc from './assets/phone.svg'
import plusSignSrc from './assets/plus-sign.svg'
import heartSrc from './assets/heart.svg'
import moneyCheckSrc from './assets/money-check.svg'
import coinArrowUpOutSrc from './assets/coin-arrow-up-out.svg'
import coinEllipsisSrc from './assets/coin-ellipsis.svg'
import coinShieldSrc from './assets/coin-shield.svg'

const DarkBox = styled.span`
  background-color: ${graphite};
  border-radius: 6px;
  padding: 4px;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & img {
    width: auto;
    height: 18px;
  }

  @media ${device.tablet} {
    width: 28px;
    height: 28px;
  }

  @media ${device.desktopLG} {
    width: 35px;
    height: 35px;
  }

  @media ${device.desktopXXL} {
    width: 43px;
    height: 43px;
  }
`

export enum DarkBoxGraphs {
  'money',
  'phone',
  'plus-sign',
  'heart',
  'money-check',
  'coin-arrow-up-out',
  'coin-ellipsis',
  'coin-shield',
}

type DarkBoxWithGraphProps = {
  graph?: DarkBoxGraphs;
}

export const DarkBoxWithGraph = ({ graph }: DarkBoxWithGraphProps) => {
  const GraphFactory = () => {
    switch (graph) {
      case DarkBoxGraphs.money:
        return <img src={moneySrc} />
      case DarkBoxGraphs.phone:
        return <img src={phoneSrc} />
      case DarkBoxGraphs['plus-sign']:
        return <img src={plusSignSrc} />
      case DarkBoxGraphs.heart:
        return <img src={heartSrc} />
      case DarkBoxGraphs['money-check']:
        return <img src={moneyCheckSrc} />
      case DarkBoxGraphs['coin-arrow-up-out']:
        return <img src={coinArrowUpOutSrc} />
      case DarkBoxGraphs['coin-ellipsis']:
        return <img src={coinEllipsisSrc} />
      case DarkBoxGraphs['coin-shield']:
        return <img src={coinShieldSrc} />
      default:
        return null
    }
  }
  return (
    <DarkBox>
      <GraphFactory />
    </DarkBox>
  )
}
