import React from 'react'
import { CrediarioDigitalSectionProps } from '../../types'
import * as S from './styles'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import SectionComponent from 'src/components/SectionComponent'
import { graphite, orange } from 'src/styles/newColors'
import { CrediarioDigitalH2 } from '../../styles'
import { InterP } from 'src/components/Text/BodyText'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { ButtonWithDeepLink } from '../components/_ButtonWithDeepLink'
import Picture from 'src/components/Picture'
import { useQRCodeModal } from 'src/context/QRCodeModalContext/QRCodeModalContext'

type QuemPodeComprarProps = CrediarioDigitalSectionProps

const TEXT_CONTENT = {
  heading: 'Quem pode comprar no Crediário?',
  body: 'Essa forma de pagamento está disponível para clientes Inter selecionados. Pra parcelar no Crediário Digital, acesse o <a href="https://intergo.app/2ae88a71">Shopping do Inter</a> e verifique se ele já está liberado pra você.',
  panelLead: 'O Crediário Digital é pra você que:',
  panelItems: [
    '<strong>Não tem limite no cartão</strong> de crédito pra fazer uma compra.',
    'Quer comprar um <strong>celular parcelado no carnê</strong> pela internet.',
    '<strong>Não tem cartão de crédito</strong> e quer comprar parcelado.',
    'Quer comprar o produto dos sonhos <strong>sem ocupar o limite do cartão.</strong>',
    'Ama a praticidade do pagamento automático e <strong>não quer se preocupar com boletos.</strong>',
  ],
  cta: 'Parcelar no Crediário',
}

const QuemPodeComprar = ({ id, width, sendDataLayerEvent, dataLayerSection }: QuemPodeComprarProps) => {
  return (
    <SectionComponent
      id={id}
      bgColor={orange.extra}
      minHeight={{ sm: '988px', md: '687px', lg: '758px', xl: '839px', xxl: '920px' }}
    >
      {width < WIDTH_MD &&
        <QuemPodeComprar.MobileView width={width} sendDataLayerEvent={sendDataLayerEvent} dataLayerSection={dataLayerSection} />}
      {width >= WIDTH_MD && (
        <QuemPodeComprar.DefaultView width={width} sendDataLayerEvent={sendDataLayerEvent} dataLayerSection={dataLayerSection} />
      )}
    </SectionComponent>
  )
}

QuemPodeComprar.MobileView = ({ width, sendDataLayerEvent, dataLayerSection }: Pick<QuemPodeComprarProps, "width"| "dataLayerSection" | "sendDataLayerEvent">) => {
  const url = 'https://intergo.app/2ae88a71'
  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })
  }
  return (
    <>
      <div className='col-12'>
        <S.QuemPodeComprarTextContent>
          <CrediarioDigitalH2 color='#fff'>
            {TEXT_CONTENT.heading}
          </CrediarioDigitalH2>
          <InterP
            color='#fff'
            fontSize={{ sm: '14px' }}
            lineHeight={{ sm: "16px" }}
            dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.body }}
          />
        </S.QuemPodeComprarTextContent>
      </div>
      <div className='col-12'>
        <S.QuemPodeComprarBox>
          <ul className='list'>
            <InterP
              fontSize={{ sm: '14px' }}
              lineHeight={{ sm: '16px' }}
              color={graphite}
              className='list__lead'
            >
              {TEXT_CONTENT.panelLead}
            </InterP>
            {TEXT_CONTENT.panelItems.map((item, index) => (
              <li key={index} className='list__item'>
                <IconsSwitch customPath='' icon='status/ic_check_circle_fill' lib='interco' size='XL' color={orange.extra} />
                <InterP
                  color='#000'
                  fontSize={{ sm: '14px' }}
                  lineHeight={{ sm: '16px' }}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </li>
          ))}
          </ul>
          <S.CtaBox>
            <ButtonWithDeepLink
              renderDeepLinkWhen={width < WIDTH_MD}
              url={url}
              onClick={handleCtaClick}
              text={TEXT_CONTENT.cta}
            />
          </S.CtaBox>
        </S.QuemPodeComprarBox>
      </div>
      <div className='col-12'>
        <Picture
          src='https://central-imagens.bancointer.com.br/images-without-small-versions/shop-dobra3-360/image.webp'
          srcSm='https://central-imagens.bancointer.com.br/images-without-small-versions/shop-dobra3-360/image.webp'
          alt='Homem segurando o celular com as duas mãos enquanto olhada para o alto pensativo'
          minHeight={{ sm: '310px' }}
          width='100%'
        />
      </div>
    </>
  )
}

QuemPodeComprar.DefaultView = ({ width, sendDataLayerEvent, dataLayerSection }: Pick<QuemPodeComprarProps, "width"| "dataLayerSection" | "sendDataLayerEvent">) => {
  // eslint-disable-next-line @typescript-eslint/typedef
  const { setIsModalOpen } = useQRCodeModal()
  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
    })

    setIsModalOpen(true)
  }
  return (
    <>
      <div className='col-md-5 p-0 col-lg-6 px-lg-3'>
        <S.QuemPodeComprarTextContent>
          <CrediarioDigitalH2 color='#fff'>
            {TEXT_CONTENT.heading}
          </CrediarioDigitalH2>
        </S.QuemPodeComprarTextContent>
      </div>
      <div className='col-md-7 col-lg-6 px-lg-3'>
        <S.QuemPodeComprarTextContent>
          <InterP
            color='#fff'
            fontSize={{ sm: '14px' }}
            lineHeight={{ sm: "16px" }}
            dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.body }}
          />
        </S.QuemPodeComprarTextContent>
      </div>
      <div className='col-md-12 p-0'>
        <S.BackgroundImage
          role='img'
          aria-label='Homem segurando o celular com as duas mãos enquanto olhada para o alto pensativo'
        >
          <S.QuemPodeComprarBox>
            <ul className='list'>
              <InterP
                fontSize={{ sm: '14px' }}
                lineHeight={{ sm: '16px' }}
                color={graphite}
                className='list__lead'
              >
                {TEXT_CONTENT.panelLead}
              </InterP>
              {TEXT_CONTENT.panelItems.map((item, index) => (
                <li key={index} className='list__item'>
                  <IconsSwitch customPath='' icon='status/ic_check_circle_fill' lib='interco' size='XL' color={orange.extra} />
                  <InterP
                    color='#000'
                    fontSize={{ sm: '14px' }}
                    lineHeight={{ sm: '16px' }}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                </li>
              ))}
              <S.CtaBox>
                <ButtonWithDeepLink
                  renderDeepLinkWhen={width < WIDTH_MD}
                  onClick={handleCtaClick}
                  text={TEXT_CONTENT.cta}
                />
              </S.CtaBox>
            </ul>
          </S.QuemPodeComprarBox>
        </S.BackgroundImage>
      </div>
    </>
  )
}

export default withCommonSectionProps(QuemPodeComprar)
