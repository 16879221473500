import React from "react"
import { TextCommonProps } from "src/components/Text/types"
import { device } from "src/styles/breakpoints"
import styled from "styled-components"

type ColoredTagBoxProps = {
  backgroundColor: string;
  borderRadius: string;
  padding?: string;
  height?: string;
} & Pick<TextCommonProps, "fontSize" | "lineHeight">

const ColoredTagBox = styled.div<ColoredTagBoxProps>`
  background-color: ${(props: ColoredTagBoxProps) => props.backgroundColor};
  border-radius: ${(props: ColoredTagBoxProps) => props.borderRadius};
  width: fit-content;
  height: ${(props: ColoredTagBoxProps) => props.height ?? "auto"};
  padding: ${(props: ColoredTagBoxProps) => props.padding ?? "7px 17px"}; ;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  text-align: center;
  display: inline-flex;
  align-items: center;

  @media ${device.tablet} {
    font-size: ${(props: ColoredTagBoxProps) => props.fontSize?.md ?? "10px"};
    line-height: ${(props: ColoredTagBoxProps) => props.lineHeight?.md ?? "13px"};
  }

  @media ${device.desktopLG} {
    font-size: ${(props: ColoredTagBoxProps) => props.fontSize?.lg ?? "14px"};
    line-height: ${(props: ColoredTagBoxProps) => props.lineHeight?.lg ?? "16px"};
  }

  @media ${device.desktopXL} {
    font-size: ${(props: ColoredTagBoxProps) => props.fontSize?.xl ?? "14px"};
    line-height: ${(props: ColoredTagBoxProps) => props.lineHeight?.xl ?? "16px"};
  }

  @media ${device.desktopXXL} {
    font-size: ${(props: ColoredTagBoxProps) => props.fontSize?.xxl ?? "18px"};
    line-height: ${(props: ColoredTagBoxProps) => props.lineHeight?.xxl ?? "22px"};
  }
`

type ColoredTagProps = ColoredTagBoxProps & {text: string}

export const ColoredTag = ({ backgroundColor, borderRadius, text, padding, height }: ColoredTagProps) => {
  return (
    <ColoredTagBox backgroundColor={backgroundColor} borderRadius={borderRadius} padding={padding} height={height}>
      {text}
    </ColoredTagBox>
  )
}
