import React from 'react'
import * as S from './styles'
import withCommonSectionProps from 'src/hoc/withCommonSectionProps'
import { CrediarioDigitalSectionProps } from '../../types'
import SectionComponent from 'src/components/SectionComponent'
import { CrediarioDigitalH2 } from '../../styles'
import { orange, secundaryYellow } from 'src/styles/newColors'
import { WIDTH_MD, WIDTH_SM } from 'src/styles/breakpoints'
import { ButtonWithDeepLink } from '../components/_ButtonWithDeepLink'
import Picture from 'src/components/Picture'
import { ColoredTag } from '../components/_ColoredTag'
import { DarkBoxGraphs, DarkBoxWithGraph } from '../components/DarkBoxWithGraph/_DarkBoxWithGraph'
import { useQRCodeModal } from 'src/context/QRCodeModalContext/QRCodeModalContext'

type OQueECrediarioProps = CrediarioDigitalSectionProps

const TEXT_CONTENT = {
  heading: 'O que é Crediário? <br> O novo carnê digital?',
  body1: 'O Crediário Digital é uma <strong>forma de pagamento facilitada</strong> pra você comprar no Shopping do Inter.',
  body2: '<strong>Funciona como um carnê digital:</strong> você compra o produto desejado, paga uma entrada e o restante pode <strong>parcelar em até 24x.</strong>',
  cta: 'Aproveitar o Crediário',
}

const OQueECrediario = ({ id, width, dataLayerSection, sendDataLayerEvent }: OQueECrediarioProps) => {
  const url = 'https://intergo.app/2ae88a71'
  // eslint-disable-next-line @typescript-eslint/typedef
  const { setIsModalOpen } = useQRCodeModal()

  const handleCtaClick = () => {
    sendDataLayerEvent({
      section: dataLayerSection,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      section_name: TEXT_CONTENT.heading,
      redirect_url: url,
    })

    if (width >= WIDTH_MD) {
      setIsModalOpen(true)
    }
  }
  return (
    <SectionComponent
      id={id}
      minHeight={{ sm: '664px', md: '445px', lg: '590px', xl: '702px', xxl: '861px' }}
    >
      <S.OQueECrediarioTextWrapper className='col-12 text-center'>
        <div className='text-content__header'>
          <CrediarioDigitalH2 color={orange.extra}>
            {TEXT_CONTENT.heading}
          </CrediarioDigitalH2>
        </div>
        <div className='text-content__body'>
          <p className='text-content__paragraph' dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.body1 }} />
          <p className='text-content__paragraph' dangerouslySetInnerHTML={{ __html: TEXT_CONTENT.body2 }} />
        </div>
      </S.OQueECrediarioTextWrapper>
      <div className='col-12'>
        {width < WIDTH_MD && <OQueECrediario.MobileGrid />}
        {width >= WIDTH_MD && <OQueECrediario.DefaultGrid />}
        <S.CtaBox className='d-block mx-auto pt-2 pt-md-3 pt-lg-2 pt-xl-3'>
          <ButtonWithDeepLink renderDeepLinkWhen={width <= WIDTH_SM} url={url} onClick={handleCtaClick} text={TEXT_CONTENT.cta} />
        </S.CtaBox>
      </div>
    </SectionComponent>
  )
}

const CreditoLiberadoPanel = () => {
  const imgSrc = 'https://central-imagens.bancointer.com.br/images-without-small-versions/crediario-dobra2/image.webp'
  const imgAlt = 'Celular samsung de frente e de costas mostrando as 3 câmeras.'
  return (
    <S.CreditoLiberado>
      <div className='text-content'>
        <h3>Crédito liberado*</h3>
        <p className='text-content__paragraph'>Pra você comprar o produto dos sonhos</p>
        <p className='text-content__paragraph--note'>*Sujeito à análise</p>
      </div>
      <div className='img-content'>
        <Picture
          src={imgSrc}
          minHeight={{ sm: '81px', md: '83px', lg: '110px', xl: '130px', xxl: '160px' }}
          width='100%'
          alt={imgAlt}
        />
      </div>
    </S.CreditoLiberado>
  )
}

const ParcelasQueCabemNoSeuBolsoPanel = () => (
  <S.ParcelasQueCabemNoSeuBolso>
    <div>
      <DarkBoxWithGraph graph={DarkBoxGraphs['plus-sign']} />
    </div>
    <div className='text-content'>
      <h3>Parcelas que cabem no seu bolso</h3>
      <p>Divida em até 24 vezes</p>
    </div>
  </S.ParcelasQueCabemNoSeuBolso>
)

const NaoPrecisaDeCartaoPanel = () => (
  <S.NaoPrecisaDeCartao>
    <div>
      <DarkBoxWithGraph graph={DarkBoxGraphs.heart} />
    </div>
    <div className='text-content'>
      <h3>Não precisa de cartão</h3>
      <p>Parcele sem ocupar o cartão de crédito</p>
    </div>
  </S.NaoPrecisaDeCartao>
)

const OrganizacaoFinanceiraPanel = () => (
  <S.OrganizacaoFinanceira>
    <div>
      <DarkBoxWithGraph graph={DarkBoxGraphs.phone} />
    </div>
    <div className='text-content'>
      <h3>Organização financeira</h3>
      <p>Acompanhe o pagamento das parcelas pelo Super App</p>
    </div>
  </S.OrganizacaoFinanceira>
)

const PraticidadePraPagarPanel = () => (
  <S.PraticidadePraPagar>
    <div className='text-content'>
      <h3>Praticidade pra pagar</h3>
      <p>É só ter o valor disponível em conta que o pagamento é feito automaticamente</p>
    </div>
    <div className='img-content'>
      <DarkBoxWithGraph graph={DarkBoxGraphs['money-check']} />
      <ColoredTag backgroundColor={secundaryYellow} borderRadius='8px' text='Débito automático' padding='5px 8px' />
    </div>
  </S.PraticidadePraPagar>
)

OQueECrediario.MobileGrid = () => (
  <S.OQueECrediarioGrid className='grid--row-oriented'>
    <div className='grid-row grid-row--first'>
      <CreditoLiberadoPanel />
    </div>
    <div className='grid-row grid-row--second'>
      <ParcelasQueCabemNoSeuBolsoPanel />
      <NaoPrecisaDeCartaoPanel />
    </div>
    <div className='grid-row grid-row--third'>
      <OrganizacaoFinanceiraPanel />
      <PraticidadePraPagarPanel />
    </div>
  </S.OQueECrediarioGrid>
)

OQueECrediario.DefaultGrid = () => (
  <S.OQueECrediarioGrid className='grid--column-oriented'>
    <div className='grid-column grid-column--first'>
      <CreditoLiberadoPanel />
      <ParcelasQueCabemNoSeuBolsoPanel />
    </div>
    <div className='grid-column grid-column--second'>
      <NaoPrecisaDeCartaoPanel />
      <OrganizacaoFinanceiraPanel />
    </div>
    <div className='grid-column grid-column--third'>
      <PraticidadePraPagarPanel />
    </div>
  </S.OQueECrediarioGrid>
)

export default withCommonSectionProps(OQueECrediario)
