import React from 'react'
import { Button } from '@interco/inter-ui/components/Button'
import styled from 'styled-components'
import { orange } from 'src/styles/newColors'

export const NoDecorationLink = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  margin: 0 auto;
  display: block;
`

type ButtonWithDeepLinkProps = {
  onClick: () => void;
  url?: string;
  renderDeepLinkWhen: boolean;
  text: string;
  variant?: 'orange-extra'|'orange-clary';
}

export const ButtonWithDeepLink = ({ onClick, url, renderDeepLinkWhen, text, variant = 'orange-extra' }: ButtonWithDeepLinkProps) => {
  const color = {
    'orange-extra': orange.extra,
    'orange-clary': orange.clay,
  }
  if (!renderDeepLinkWhen) {
    return (
      <Button fullWidth onClick={onClick} style={{ backgroundColor: color[variant] }}>
        {text}
      </Button>
    )
  }
  return (
    <NoDecorationLink
      href={url}
      target='_blank'
      rel='noreferrer'
      onClick={onClick}
    >
      <Button fullWidth style={{ backgroundColor: color[variant] }}>
        {text}
      </Button>
    </NoDecorationLink>
  )
}
