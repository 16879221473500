import React from 'react'
import { H1Citrina, H2Citrina } from 'src/components/Text/Headings'

export const CrediarioDigitalH1 = ({ children, color }: {children: string | TrustedHTML; color?: string}) => {
  return (
    <H1Citrina
      color={color}
      fontSize={{ sm: '24px', md: '28px', lg: '40px', xl: '48px', xxl: '56px' }}
      lineHeight={{ sm: '28px', md: '32px', lg: '44px', xl: '52px', xxl: '60px' }}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

export const CrediarioDigitalH2 = ({ children, color }: {children: string | TrustedHTML; color?: string}) => {
  return (
    <H2Citrina
      color={color}
      className='fw-700'
      fontSize={{ sm: '24px', md: '28px', lg: '40px', xl: '48px', xxl: '56px' }}
      lineHeight={{ sm: '28px', md: '32px', lg: '44px', xl: '52px', xxl: '60px' }}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}