import { device } from "src/styles/breakpoints"
import styled from "styled-components"

export const QuemPodeComprarTextContent = styled.div`
  text-align: center;
  h2 {
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  @media ${device.tablet} {
    text-align: left;
    margin-bottom: 1.5rem;
    h2, p {
      margin: 0;
    }
  }
`

export const BackgroundImage = styled.div`
  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/shop-dobra3-768/image.webp');
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 580px;
    background-position: bottom;
    position: relative;
  }
  @media ${device.desktopLG} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/shop-dobra3-1024/image.webp');
    min-height: 560px;
  }

  @media ${device.desktopXXL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/shop-dobra3-1440/image.webp');
    min-height: 580px;
  }

  @media ${device.desktopXXLL} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/shop-dobra3-1920/image.webp');
    min-height: 580px;
  }
`

export const QuemPodeComprarBox = styled.div`
  background-color: #fff;
  border-radius: 24px;
  padding: 1.5rem;
  width: 100%;
  margin-bottom: 1rem;

  .list {
    & .list__lead {
      margin-bottom: 1rem;
    }
    & .list__item {
      & svg {
        width: 25px;
        height: 25px;
      }
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    & .list__item:last-child {
      margin-bottom: 1.5rem;
    }
  }

  @media ${device.tablet} {
    padding: 1rem;
    max-width: 335px;
    position: absolute;
    left: 40px;
    bottom: -10px;
  }

  @media ${device.desktopLG} {
    max-width: 470px;
    bottom: 10px;
    .list {
      & .list__item {
        margin-bottom: 1.5rem;
        & svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  @media ${device.desktopXL} {
    & {
      left: 10%;
    }
  }

  @media ${device.desktopXXL} {
    max-width: 591px;
    left: 40px;
    bottom: 0;
  }
`

export const CtaBox = styled.div`
`
