import React, { useEffect } from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import {
  S1ParceleSuasCompras,
  S2OQueECrediario,
  S3QuemPodeComprar,
  S4ComoFuncionaAEntrada,
  S5CompreEPague,
  S6SimplesComprarParcelado,
  FAQCrediario,
} from './_sections/_index'
import { Theme, initTheme } from '@interco/inter-ui'
import QRCodeModalProvider from 'src/context/QRCodeModalContext/QRCodeModalContext'
import qrCode from './_assets/qr-code-crediario-digital.png'

const CrediarioDigital = () => {
  useEffect(() => {
    initTheme(Theme.PF)
  })

  return (
    <Layout pageContext={pageContext}>
      <QRCodeModalProvider
        title='Aproveite o Crediário Digital pelo Super App Inter' instructions='Aponte a câmera do celular para o QR Code e você será direcionado para o Super App.' qrCode={qrCode}
      >
        <S1ParceleSuasCompras id='section-parcele-suas-compras' dataLayerSection='section-parcele-suas-compras' />
        <S2OQueECrediario id='section-contrate-sem-burocracia' dataLayerSection='section-contrate-sem-burocracia' />
        <S3QuemPodeComprar id='section-quem-pode-comprar' dataLayerSection='section-quem-pode-comprar' />
        <S4ComoFuncionaAEntrada id='section-como-funciona-a-entrada' dataLayerSection='section-como-funciona-a-entrada' />
        <S5CompreEPague id='section-compre-e-pague' dataLayerSection='section-compre-e-pague' />
        <S6SimplesComprarParcelado id='section-compre-e-pague' dataLayerSection='section-compre-e-pague' />
        <FAQCrediario id='section-faq-crediario' />
      </QRCodeModalProvider>
    </Layout>
  )
}

export default CrediarioDigital
